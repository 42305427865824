<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    :is-loading="isLoading"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <!-- Headers -->
    <template
      slot="table-column"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'checked'">
        <b-form-checkbox
          v-model="selectAll"
          :value="true"
          :unchecked-value="false"
          class="mr-2 vgt-checkbox-col"
        />
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template slot="emptystate">
      <div
        v-if="isLoading"
        class="text-center"
      >
        Fetching data
      </div>
      <div
        v-else
        class="text-center"
      >
        No data for table
      </div>
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.field === 'participants'"
      >
        <router-link
          :to="{
            name: 'champion-program-participant',
            params: { id: defaultProgramId, participantId: props.row.participantId },
          }"
          class="table-primary-link"
        >
          {{ props.formattedRow[props.column.field] }}
        </router-link>
      </div>
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        {{ props.formattedRow[props.column.field] }}
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import { VBToggle, VBTooltip, BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import formsService from "@/services/formsService";
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
} from "@/libs/utils";
import { userRoles, formStatus, formStatusSelect, formStatusDisplay } from '@/models';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { participantRolesDisplay } from '@models/userRoles';
import { roleAlias } from "@/@aom-core/utils/utils";



export default {
  components: {
    VueGoodTable,
    TablePagination,
    BFormCheckbox
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Participants",
          field: "participants",
          filterOptions: {
            enabled: true,
            placeholder: "Search Participants",
          },
          width: "10em",
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay,
          },
          width: "5em",
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: formStatusSelect,
          },
          width: "10em",
        },
        {
          label: "Submitted",
          field: "submitted_at",
          filterOptions: {
            enabled: true,
          },
          thClass: 'vgt-left-align',
          tdClass: "vgt-left-align search-date-selector",
          width: "8em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          label: "Completed",
          field: "completed_at",
          filterOptions: {
            enabled: true,
          },
          thClass: 'vgt-left-align',
          tdClass: "vgt-left-align search-date-selector",
          width: "8em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      filterFields: [
        "participants",
        "role",
        "status",
        "submitted_at",
        "completed_at"
      ],
      formStatus,
      formStatusDisplay,
      selected: [],
      selectAll: false,
      bulkSelection: [],
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems(true);
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-submitted_at"));
    datePickers.push(document.getElementsByName("vgt-completed_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (params[0].field === "participants") {
          field = "user.full_name";
        } else if (params[0].field === "role") {
          field = "form.roles_name";
        } 
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of this.filterFields) {
        if (params.columnFilters[col]) {
          if (col === "participants") {
            columnFilters.push({
              field: 'user.full_name',
              value: params.columnFilters[col],
            });
          } else if (col === "role") {
            const role = participantRolesDisplay.filter(r => Number(r.value) === Number(params.columnFilters[col]));
            if (role && role.length) {
              columnFilters.push({
                field: 'form.roles.name',
                value: role[0].text,
              });
            }
          } else if (col === "status") {
            columnFilters.push({
              field: 'status_id',
              value: params.columnFilters[col],
            });
          } else if (col === "submitted_at" || col === "completed_at") {
            const dateRange = params.columnFilters[col].split(" to ");
            if (dateRange.length > 1) {
              const startDate = dateRange[0] + "T00:00:00Z";
              const endDate = dateRange[1] + "T23:59:59Z";
              columnFilters.push({
                field: col,
                value: [startDate, endDate],
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await formsService.getProgramApplicationSetResult(
          this.$route.params.id,
          this.$route.params.application,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(i => ({
          ...i,
          participants: i.user.full_name,
          participantId: i.user.id,
          role: i.form.roles.filter(r => r.id !== userRoles.PARTICIPANT_CANDIDATE).map(r => roleAlias(r.id, this.defaultProgram)).join(","),
          status: i.status ? i.status.name : ""
        }));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, 
      perPage, 
      page
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
